





























































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import VesselsModule from "@/store/clients/Vessels.module";
import UserModule from "@/store/clients/User.module";
import FoulingDiagnosticsClient from "Clients/fouling-diagnostics-client";
import { FoulingDiagnostics as Diagnostics } from "@/types/FoulingDiagnostics";

const Vessels = getModule(VesselsModule, store);
const User = getModule(UserModule, store);

@Component({
  components: {},
})
export default class FoulingDiagnostics extends Vue {
  vessel = Vessels.currentVessel;
  diagnostics: Diagnostics | undefined;
  isLoading = true;

  async created(): Promise<void> {
    if (!User.isAdmin) return;
    if (!this.vessel) return;
    this.diagnostics = await FoulingDiagnosticsClient.getDiagnostics(this.vessel.id);
    this.isLoading = false;
  }
}
